<template>
  <Title pageTitle="ORDER T-SHIRTS" pageDiscrption="オーダーTシャツ" />
  <div class="container">
    <div class="order">
      <h2 class="order__title heading-2">
        オーダーTシャツはお客様のスタイルに合わせた<br />美しいラインを最大限に引き出します
      </h2>
      <div class="order__category mb-rg">Order T-shirts</div>
      <div class="order__content">
        <div class="order__content--item">
          <figure>
            <img
              src="../assets/img/order-general.jpg"
              alt="一般的なTシャツのシルエット"
            />
            <figcaption>一般的なTシャツ</figcaption>
          </figure>
          <p>
            上の写真が一般的に量産されているTシャツを着用した場合のシルエットです。
          </p>
          <p class="mb-sm">
            肩幅が合っていないため肩が落ちてしまい、袖幅も腕に比べてかなり広くなっています。着幅から裾幅のラインも直線のため余裕があり着丈も長めです。首回りは詰まり気味で窮屈な感じがします。
          </p>
        </div>
        <div class="order__content--item">
          <figure>
            <img
              src="../assets/img/order-ms.jpg"
              alt="オーダーTシャツのシルエット"
            />
            <figcaption>オーダーTシャツ</figcaption>
          </figure>
          <p>
            こちらの写真は同じモデルのスタイルに合わせて製作したオーダーTシャツです。
          </p>
          <p>
            体のサイズに合わせて作られているため、体型に合った綺麗なシルエットを実現し、全体的にスッキリしているのが分かるかと思います。お好みにより首回りの広さやネックの形状、着丈や袖丈の長さ、着幅や裾幅など細かく変更いただけます。
          </p>
        </div>
      </div>

      <h2 class="order__title heading-2">
        希望のサイズに仕上げるために。<br />生地の特性を考慮した細い調整を行なっています。
      </h2>
      <div class="order__category mb-rg">T-shirts size</div>
      <div class="order__content">
        <div class="order__content--item">
          <h4 class="heading-4">生地の縮みを最小限に抑えています</h4>
          <p>
            Tシャツに使用されている綿素材はその編み方の特性上、伸縮性に優れている反面サイズが変わりやすいという性質があります。特に初回洗濯時は多くの水分を吸収し編み目が縮まることで生地自体も大きく縮小する傾向にあります。
          </p>
          <p>
            そのため当店の全てのTシャツは初回洗濯時の縮みを軽減するために縫製前にウォッシュ加工を施しております。ウォッシュ加工を施しても洗濯時に全く縮まないということはありませんが、意図しない大幅なサイズの変化は避けられます。
          </p>
          <p class="mb-sm">
            サイズの変化は日頃の取り扱いや洗濯・乾燥の仕方も大きく影響しますので、下記のメンテナンス項目もご参考ください。
          </p>
          <h4 class="heading-4">
            着用による変形を考慮したサイズ設定をしています
          </h4>
          <p>
            Tシャツ生地には編み目の向きがあります。通常の天竺素材であれば縦目と横目があり、横目が伸びやすく縦目が伸びにくい仕様となっております。生地はループ状に編まれているので、必然的に横目が伸びれば縦目が縮まってきます。
          </p>
          <p>
            通常の生活では上下運動よりも左右の動きが多いため、普段Tシャツを着用していると傾向として着幅が広がり、着丈が縮まる事が多いかと思います。
          </p>
          <p class="mb-sm">
            そこで横に伸びやすく縦が縮まる傾向があることを考慮して、当店では予め着丈を長めに設定しています。着丈はTシャツの中でもっともストロークが長い分、縮みの影響を受けやすい箇所です。予め着丈を長めに設定する事で洗濯による縮みや、日常生活での縦目の縮小を軽減する事ができると考えております。
          </p>
        </div>
        <div class="order__content--item">
          <h4 class="heading-4">当店規定のTシャツの採寸方法</h4>
          <p>
            当店では下記の基準で各サイズを採寸しております。テイラーTシャツ・カスタマイズTシャツ共通ですのでサイズ確認の際にご参考ください。
          </p>
          <figure class="measuring">
            <img src="../assets/img/measuring.svg" alt="Tシャツ採寸基準" />
          </figure>
          <p>
            上記イラスト通りに各サイズを計測しております。着丈はネック後ろの一番低い箇所から裾までとしています。着幅の計測位置はシワになりやすいので、袖を上に上げた状態で計測しています。ウエストがシェイプしている場合は一番細い部分としています。
          </p>
          <p>
            お客様ご自身で計測する際は、Tシャツによれやシワが無いように平らな場所で採寸してください。
          </p>
        </div>
      </div>

      <h2 class="order__title heading-2">
        100%のTシャツを目指して。<br />パターンから裁断、縫製まで全て自社で行っております。
      </h2>
      <div class="order__category mb-rg">Our works</div>
      <div class="order__content">
        <div class="order__content--item">
          <h4 class="heading-4">お客様一人ひとりのために</h4>
          <p>
            お客様一人ひとりのご要望に沿ったTシャツを作成するために、当店ではTシャツ製作に関わる全ての工程を自社で行っております。
          </p>
          <p class="mb-sm">
            自社で一枚一枚生産することで、大量生産では出来ないお客様の細かいニーズに答えることができ、業界初の試着サービスやお直しサービスなどの他店にはないサービスを実現することができました。
          </p>
          <h4 class="heading-4">Tシャツもオーダーメイドの時代へ</h4>
          <p>
            市販のTシャツの数種類しかないサイズの中で、どれだけの人が自分の体に合ったTシャツを着用しているのでしょうか？
          </p>
          <p>
            伸び縮みするTシャツだからそれなりに着用できてしまいますが、オーダーメイドでTシャツを作ることで得られるフィット感や着心地、体のラインに合ったシルエットの美しさなどは市販のTシャツでは味わえないものがあります。
          </p>
          <p class="mb-sm">
            私たちはオーダーTシャツの素晴らしさを多くの人に知ってもらうために日々活動しております。
          </p>
        </div>
        <div class="order__content--item">
          <h4 class="heading-4">製作から納品まで</h4>
          <p>
            テイラーTシャツをご注文いただいてから、まずはお送りいただいた情報を元にお客様の体型や好みを考慮しながらパターン（型紙）を作成していきます。次にパターンを元にご注文と同じ生地を使用して裁断・縫製し、サンプルTシャツを製作します。
          </p>
          <p>
            この段階で注文通りの寸法と希望通りのデザインになっているかの確認をし、修正が必要な箇所があれば再度調整していきます。お客様のご要望に合ったと判断できるサンプルTシャツが完成しましたらお客様にお送り致します。
          </p>
          <p>
            お客様にサンプルTシャツをご試着いただき、希望通りのサイズ感になっていれば商品用Tシャツの製作を開始します。変更したい箇所があれば再度パターンを修正します。アパレル業界で長年の経験のある熟練した職人が心を込めて一点ずつ製作していますので、細部にまでこだわった妥協のない商品として自信を持ってお届けしております。
          </p>
        </div>
      </div>

      <h2 class="order__title heading-2">
        着心地やサイズ感を維持してもらいたい。<br />洗濯や乾燥の仕方でTシャツの状態は変わってきます。
      </h2>
      <div class="order__category mb-rg">Maintenance</div>
      <div class="order__content">
        <div class="order__content--item">
          <h4 class="heading-4">洗濯は手洗いをオススメしています</h4>
          <p>
            洗濯方法について当店では生地の劣化がもっとも少ない手洗いをお勧めしております。
          </p>
          <p>
            Tシャツが入る大きさのある容器に水と洗剤を入れ、浸して軽くすすいでください。洗濯機を使用する場合は洗濯ネットを使用することでTシャツにかかる負担を軽くすることができます。毛羽立ちや劣化の原因の一つは洗濯時の摩擦にありますので洗濯機を使用する場合はもちろん、手洗い時もTシャツを裏返しにしてから洗うようにしてください。
          </p>
          <p>
            濃い色(ブラックやネイビー)のTシャツは洗濯の環境により色落ちしてしまうことがあります。白い素材のものや色が付着しやすい衣類との洗濯は避けるようにしてください。同様にデニムなどの色落ちする物と一緒に洗うと染料により変色してしまいますのでご注意願います。また漂白剤の入った洗剤は生地を傷めてしまったり、色むらの原因となりますので使用しないでください。
          </p>
          <figure class="mb-sm">
            <img src="../assets/img/howtoDry.png" alt="Tシャツの乾燥方法" />
          </figure>
          <h4 class="heading-4">乾燥機は使用しないでください</h4>
          <p class="mb-sm">
            ドライクリーニングや乾燥機を使用すると、自然乾燥に比べて極端に生地が縮んでしまう可能性があります。さらに生地を傷めてしまうこともありますので、乾燥機は使用しないでください。
          </p>
          <h4 class="heading-4">Tシャツの保管方法</h4>
          <p class="mb-sm">
            Ｔシャツの保管は、シワがつかないようにたたんでタンスなどに収納することをお勧めします。ハンガーを使用すると型崩れの原因になりますのでご注意ください。直射日光に長時間さらすと変色することがあるので、日光があたらないような場所で保管してください。
          </p>
          <h4 class="heading-4">
            化学繊維生地のメンテナンスと取り扱いについて
          </h4>
          <p>
            当店では綿素材以外にポリエステル、ポリウレタンのような合成繊維や再生繊維を使用した化繊生地のTシャツを取り扱っております。伸縮性に優れたミラウェーブ、クールマックス素材が化繊生地になります。
          </p>
          <p>
            化繊生地はサイズ変化が少なくシワのつきにくいのが特徴の素材です。洗濯後、きれいに整えて干すことで変形やシワの少ない状態でご着用いただけます。シワが出来てしまいアイロン掛けをする際はあて布を使用して適正な温度で使用してください。化繊の生地はアイロン温度により生地焼けやテカリが起こる可能性がございますのでご注意ください。
          </p>
          <p class="mb-sm">
            また化繊生地は非常に伸縮性がありますので生地を大きく伸ばしたり、強い力を加えてしまうと糸切れを起こしてしまう恐れがございます。一般的な着用では問題ありませんが故意に生地を伸ばすなど無理な力を加えた商品は保証対象外となりますのでご注意ください。
          </p>
        </div>
        <div class="order__content--item">
          <h4 class="heading-4">
            綿素材のTシャツのシワやヨレはスチームでリセット
          </h4>
          <p>
            綿素材は肌触りの良さや通気性や吸水性に優れるなどのメリットがある反面、縮みやすくシワが付いたりヨレやすいなどのデメリットもあります。当店取り扱いの綿100%素材のTシャツも同様に洗濯後はシワやヨレが起こります。
          </p>
          <p>
            洗濯後についたシワやヨレをはスチームアイロンでリセットできます。市販のスチームアイロンで軽く整えるだけで簡単にシワやヨレを解消することができます。
          </p>
          <p>
            またスチームアイロンでシワやヨレを取り除いた後にスチームをオフにして生地の湿気がなくなるまでアイロンをかけるとシワのない状態を長く保つことができます。
          </p>
          <p>
            スチームアイロンを使用する際は生地にスチームを軽く当てるように行なってください。Tシャツの生地とアイロン面が触る程度で使用するのが良いスチームのかけ方です。アイロンを強く押し付けると生地が変色したり変形することがあります。生地自体を傷めることにもなりますのでご注意ください。
          </p>
          <p>
            また取り扱いに関しましては、高温となりますので火傷や火災などに十分気を付けてご使用ください。
          </p>
          <figure class="mb-sm">
            <img
              src="../assets/img/laundry.jpg"
              alt="洗濯後のTシャツのリセット方法"
            />
          </figure>
          <h4 class="heading-4">Tシャツが変形してしまったときは</h4>
          <p>
            当店では綿素材などの縮みやすい生地に対して縫製前にウォッシュ加工を施し、初回洗濯時に起こる生地の縮みを最小限に抑えるよう処理しております。しかし綿は天然素材ですので性質上、洗濯・乾燥の仕方や保管の方法などでサイズが変わってしまうことがあります。
          </p>
          <p class="mb-sm">
            Tシャツのサイズ変わってしまった時は、洗濯後に吊り干しをする際、縮んでしまった向きとは逆の方向に少し伸ばしながら形を整えて干していただくと繊維の縮みが少しずつ戻り変形が解消される事があります。変形が気になる方は一度お試しください。
          </p>
          <h4 class="heading-4">シミや黄ばみができてしまったら</h4>
          <p>
            日常生活によるTシャツの襟やワキの下などの黄ばみや汚れは程度に応じて酸素系漂白剤でケアをしてください。漂白剤の種類によって効果が異なりますが、塩素系漂白剤は脱色（色はげ）などを起こしやすく生地が傷んでしまうのでご使用は避けて下さい。
          </p>
          <p class="mb-sm">
            つけ置きをする場合は、約30～40℃のぬるま湯に粉末タイプの洗剤を溶かし入れ（1Lの水に5gが目安）、約30分のつけ置きが効果的です。シミが出来てしまった場合は、シミ抜きのプロ（クリーング店など）におまかせしましょう。黄ばみや汚れは汗などが主な原因ですが、収納時の高温多湿により発生しやすくなりますので出来るだけこのような条件を作らない工夫をすることも大切です。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title.vue'
export default {
  components: {
    Title,
  },
}
</script>

<style lang="scss" scoped>
.order {
  & div:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0;
  }
  text-align: center;

  &__category {
    text-align: center;
    width: 180px;
    background-color: #000;
    text-transform: uppercase;
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 2px;
    padding: 5px;
    border-radius: 100px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8rem;
    margin-bottom: 10rem;
    border-bottom: 1px solid var(--color-dark);

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 6rem;
      margin-bottom: 6rem;
    }

    &--item {
      width: 48%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      & .heading-4 {
        text-align: left;
      }

      & figure {
        position: relative;
        z-index: -1;

        & figcaption {
          position: absolute;
          bottom: 1.5rem;
          left: 1.5rem;
          background-color: #000;
          font-size: 13px;
          font-weight: 300;
          padding: 3px 10px;
        }
      }

      & img {
        width: 100%;
        margin-bottom: 2rem;
        display: block;
      }
    }
  }
}
.measuring {
  text-align: center;

  & img {
    width: 75%;
    display: inline-block;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
